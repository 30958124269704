"use client";

import React from "react";
import { useTranslation } from "react-i18next";
import {FaBalanceScale, FaUserCircle} from "react-icons/fa";
import {BiCheckboxChecked} from "react-icons/bi";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="hidden h-[104px] w-full shrink-0 bg-white xl:block mt-auto">
      <div className="mx-auto grid h-full w-full max-w-6xl grid-cols-1 place-items-center justify-between gap-[24px] md:grid-cols-3">
        <div className="flex flex-row items-center gap-3.5">
          <div className="grid h-[59px] w-[59px] place-items-center rounded-full theme-bg-color bg-opacity-[0.15] text-white">
            <FaUserCircle size={40} />
          </div>
          <div className="flex flex-col text-left">
            <h3 className="text-lg font-bold">{t("Home.Section2.key1")}</h3>
            <p className="text-base">{t("Home.Section2.key2")}</p>
          </div>
        </div>
        <div className="flex flex-row items-center gap-3.5">
          <div className="grid h-[59px] w-[59px] place-items-center rounded-full theme-bg-color bg-opacity-[0.15] text-white">
            <FaBalanceScale size={40} />
          </div>
          <div className="flex flex-col text-left">
            <h3 className="text-lg font-bold">{t("Home.Section2.key3")}</h3>
            <p className="text-base">{t("Home.Section2.key4")}</p>
          </div>
        </div>
        <div className="flex flex-row items-center gap-3.5">
          <div className="grid h-[59px] w-[59px] place-items-center rounded-full theme-bg-color bg-opacity-[0.15] text-white">
            <BiCheckboxChecked size={40} />
          </div>
          <div className="flex flex-col text-left">
            <h3 className="text-lg font-bold">{t("Home.Section2.key5")}</h3>
            <p className="text-base">{t("Home.Section2.key6")}</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
